import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../components/Loading";
import { replaceAll } from "../utils/common";
import { useRecoilState } from "recoil";
import { toastState } from "../utils/atom";
const CryptoJS = require("crypto-js");

export default () => {
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [toast, setToast] = useRecoilState(toastState);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const txt = e.target.txt.value;
        const is_manual = e.target.is_manual.checked;
        
        var resp = null;
        if (is_manual) {
            resp = await axios({
                url: `${process.env.REACT_APP_HOST}/dev/decrypt`,
                method: "POST",
                data: {
                    txt
                },
            });
        } else {
            resp = await axios({
                url: `${process.env.REACT_APP_HOST}/dev/decrypt`,
                method: "GET",
            });
        }

        console.log(resp.data);

        setData(resp.data);
        setLoading(false);
    };

    const copy = (i) => {
        const el = document.querySelector(`#row_${i}`);
        const text = el.innerText;
        navigator.clipboard.writeText(text);
        setToast({
            isToast: true,
            message: "복사 되었습니다.",
        });
    };

    return (
        <div className="p-0" style={{ fontSize: "10px" }}>
            <form onSubmit={handleSubmit} className="m-2">
                <textarea className="form-control form-control-sm" rows={2} name="txt"></textarea>
                <div className="d-flex flex-row">
                    <input className="btn btn-outline-secondary btn-sm mt-1" type="submit" />
                    <div className="form-check ms-5 fs-6 d-flex align-items-center">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value="1"
                            id="flexCheckDefault"
                            name="is_manual"
                        />
                        <label className="form-check-label ms-1" htmlFor="flexCheckDefault">
                            수동
                        </label>
                    </div>
                </div>
            </form>

            {Array.isArray(data) && (
                <table>
                    <tbody>
                        {data.map((row, i) => (
                            <tr key={i} style={{ borderBottom: "1px solid #aaa" }}>
                                <td style={{ width: "100px", whiteSpace: "nowrap" }} valign="middle" align="right">
                                    {row.name}
                                </td>
                                <td>
                                    <input type="button" onClick={() => copy(i)} value="copy" />
                                </td>
                                <td id={`row_${i}`}>
                                    {row.type === "m3u8" ? (
                                        <a href={row.url} target="_blank">
                                            {`array('ltype' => '1', 'ch' => '${row.url}'),`}
                                        </a>
                                    ) : (
                                        <>
                                        {`array('ltype' => '2', 'ch' => '${row.url}'),`}
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {isLoading && <Loading isLoading={isLoading} />}
        </div>
    );
};
// http://207.148.118.60/tvapi/data.php?packagename=com.fbfb23.fb773&version=1.2.7
