import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../components/Loading";
import { replaceAll } from "../utils/common";

export default () => {
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const txt = e.target.txt.value;
        var tmp = replaceAll(txt, "[MainActivity6::onCreate]", "");
        tmp = replaceAll(tmp, "D ", "");
        tmp = replaceAll(tmp, "http", "\narray('ltype' => '1', 'ch' => 'http");
        const tmpArr = tmp.split("\n");
        var arr = [];
        for (const o of tmpArr) {
            if (o.includes("http")) {
                arr.push(`${o.trim()}'),`);
            }
        }
        setData(arr);
        setLoading(false);
    };

    return (
        <div className="p-4" style={{ fontSize: "12px" }}>
            <form onSubmit={handleSubmit}>
                <textarea className="w-100" rows={10} name="txt"></textarea>
                <input type="submit" />
            </form>
            <hr />
            {data.map((row, i) => (
                <div key={i}>{row}</div>
            ))}
            {/* <div style={{ whiteSpace: "pre-line" }}>{data}</div> */}
            {isLoading && <Loading isLoading={isLoading} />}
        </div>
    );
};
