import { useEffect, useState, useRef } from "react";
import Layout from "../../components/Layout";
import { DiCss3, DiJavascript, DiNpm } from "react-icons/di";
import { Falistor, FaRegFolder, FaRegFolderOpen } from "react-icons/fa";
import TreeView, { flattenTree } from "react-accessible-treeview";
import { tokenIssue } from "../../utils/store";
import { useRecoilState } from "recoil";
import { loadingState } from "../../utils/atom";
import axios from "axios";

var nodeArr = [];
var codes = [];
var sorts = [];

export default () => {
    const [loading, setLoading] = useRecoilState(loadingState);
    const [values, setValues] = useState({});
    const [expandedIds, setExpandedIds] = useState([]);
    const [list, setList] = useState({ list: [], codes: [], sorts: [] });
    const [treeData, setTreeData] = useState(
        flattenTree([
            {
                id: "0",
                name: "root",
                code: "root",
                parent: "0",
                children: [],
            },
        ])
    );

    const [quizCount, setQuizCount] = useState({});

    useEffect(() => {
        (async () => {
            await getList();
        })();
    }, []);

    const getList = async () => {
        setLoading(true);
        setExpandedIds([]);
        setValues({});
        const token = await tokenIssue();
        const { data } = await axios({
            url: `${process.env.REACT_APP_HOST}/codes`,
            method: "GET",
            headers: { token },
        });
        setList(data);
        setLoading(false);
        nodeArr = [];
        data.codes.map((code1, i) => {
            if (code1.length < 6) {
                if (i > 0) {
                    nodeArr.push(i);
                }
            }
        });

        codes = [];
        sorts = [];
        codes = [...data.codes];
        sorts = [...data.sorts];

        setTreeData(flattenTree(data.list));
    };

    async function getQuizCount(code1) {
        const token = await tokenIssue();
        const { data } = await axios({
            url: `${process.env.REACT_APP_HOST}/codes/get_quiz_count?code1=${code1}`,
            method: "GET",
            headers: { token },
        });
        console.log(data);
        setQuizCount(data);
    }

    if (!list) {
        return "error";
    }

    const handleClick = (e) => {
        if (e.isSelected) {
            setValues({
                ...values,
                code1: codes[e.element.id],
                sort1: sorts[e.element.id],
                name1: e.element.name,
            });

            getQuizCount(codes[e.element.id]);
        }
    };

    const handelChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const handleSave = async () => {
        setLoading(true);
        const token = await tokenIssue();
        await axios({
            url: `${process.env.REACT_APP_HOST}/codes/update`,
            method: "POST",
            data: values,
            headers: { token },
        });
        await getList();
    };

    const handleAdd = async (seq) => {
        const token = await tokenIssue();
        setLoading(true);
        const code1 = codes[seq];
        console.log(code1);
        await axios({
            url: `${process.env.REACT_APP_HOST}/codes/add`,
            method: "POST",
            data: { code1 },
            headers: { token },
        });
        await getList();
        setExpandedIds([seq]);
    };

    const handleAddRoot = async () => {
        const token = await tokenIssue();
        setLoading(true);
        await axios({
            url: `${process.env.REACT_APP_HOST}/codes/add`,
            method: "POST",
            data: { code1: "root" },
            headers: { token },
        });
        await getList();
    };

    const handleDel = async (seq) => {
        if (!window.confirm("삭제 하시겠습니까?")) {
            return;
        }
        const token = await tokenIssue();
        const code1 = codes[seq];
        console.log(code1);
        setLoading(true);
        await axios({
            url: `${process.env.REACT_APP_HOST}/codes/del`,
            method: "POST",
            data: { code1 },
            headers: { token },
        });
        await getList();
    };

    return (
        <Layout>
            <button className="btn btn-sm btn-link p-0" onClick={handleAddRoot}>
                [+추가]
            </button>
            <button onClick={() => setExpandedIds([...nodeArr])} type="button" className="btn btn-sm btn-link">
                [펼치기]
            </button>
            <button onClick={() => setExpandedIds([])} type="button" className="btn btn-sm btn-link">
                [접기]
            </button>

            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="card shadow">
                        <div className="card-body">
                            <div className="directory">
                                <TreeView
                                    onSelect={(e) => handleClick(e)}
                                    expandedIds={expandedIds}
                                    data={treeData}
                                    aria-label="directory tree"
                                    nodeRenderer={({
                                        element,
                                        isBranch,
                                        isExpanded,
                                        isSelected,
                                        getNodeProps,
                                        level,
                                    }) => (
                                        <div>
                                            {element.name && (
                                                <div className="d-flex flex-row">
                                                    <div {...getNodeProps()} style={{ paddingLeft: 20 * (level - 1) }}>
                                                        {isBranch ? (
                                                            <FolderIcon isOpen={isExpanded} />
                                                        ) : (
                                                            <FileIcon filename={element.name} />
                                                        )}
                                                        {element.name}
                                                    </div>

                                                    <div className="text-end">
                                                        {isSelected && level <= 4 && (
                                                            <div>
                                                                {level < 4 && (
                                                                    <button
                                                                        className="btn btn-sm btn-link p-0"
                                                                        onClick={() => handleAdd(element.id)}
                                                                    >
                                                                        [+]
                                                                    </button>
                                                                )}
                                                                <button
                                                                    className="btn btn-sm btn-link text-danger p-0"
                                                                    onClick={() => handleDel(element.id)}
                                                                >
                                                                    [-]
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-8">
                    <div className="card shadow">
                        <div className="card-body">
                            <div className="row">
                                <label className="col-sm-2 col-form-label">코드</label>
                                <div className="col-sm-10">
                                    <input
                                        className="form-control-plaintext form-control-sm"
                                        name="code1"
                                        type="text"
                                        readOnly={true}
                                        required={true}
                                        value={values.code1 || ""}
                                        onChange={handelChange}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <label className="col-sm-2 col-form-label">코드명</label>
                                <div className="col-sm-10">
                                    <input
                                        className="form-control form-control-sm"
                                        name="name1"
                                        type="text"
                                        required={true}
                                        value={values.name1 || ""}
                                        onChange={handelChange}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <label className="col-sm-2 col-form-label">순서</label>
                                <div className="col-sm-10">
                                    <input
                                        className="form-control form-control-sm"
                                        name="sort1"
                                        type="number"
                                        required={true}
                                        value={values.sort1 || ""}
                                        onChange={handelChange}
                                    />
                                </div>
                            </div>
                            {values.code1 && (
                                <button type="button" className="btn btn-sm btn-primary" onClick={handleSave}>
                                    저장
                                </button>
                            )}
                        </div>
                    </div>

                    <div className="card shadow">
                        <div className="card-body">
                            <div>문제수: {quizCount.cnt}</div>
                            <textarea className="w-100" style={{ height: "300px" }} value={quizCount.web_idx + ``} />
                        </div>
                    </div>
                </div>
            </div>

            <style jsx="true">{`
                .directory {
                    font-family: monospace;
                    font-size: 16px;
                    color: black;
                    user-select: none;
                    padding: 0px;
                    border-radius: 0.4em;
                }

                .directory .tree,
                .directory .tree-node,
                .directory .tree-node-group {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                }

                .directory .tree-branch-wrapper,
                .directory .tree-node__leaf {
                    outline: none;
                    outline: none;
                }

                .directory .tree-node {
                    cursor: pointer;
                }

                .directory .tree-node:hover {
                    background: rgba(255, 255, 255, 0.1);
                }

                .directory .tree .tree-node--focused {
                    background: rgba(255, 255, 255, 0.2);
                }

                .directory .tree .tree-node--selected {
                    background: rgba(48, 107, 176, 0.5);
                    flex: 1 1 auto !important;
                }

                .directory .tree-node__branch {
                    display: block;
                    flex: 1 1 auto !important;
                }

                .directory .icon {
                    vertical-align: middle;
                    padding-right: 5px;
                }
            `}</style>
        </Layout>
    );
};

const FolderIcon = ({ isOpen }) =>
    isOpen ? <FaRegFolderOpen color="f9a932" className="icon" /> : <FaRegFolder color="f9a932" className="icon" />;

const FileIcon = ({ filename }) => {
    return <DiNpm color="turquoise" className="icon" />;
    // const extension = filename.slice(filename.lastIndexOf(".") + 1);
    // switch (extension) {
    //     case "js":
    //         return <DiJavascript color="yellow" className="icon" />;
    //     case "css":
    //         return <DiCss3 color="turquoise" className="icon" />;
    //     case "json":
    //         return <FaList color="yellow" className="icon" />;
    //     case "npmignore":
    //         return <DiNpm color="red" className="icon" />;
    //     default:
    //         return null;
    // }
};
